/* Base CSS Settings */
@font-face {
    font-family: 'sfprodisplay-regular';
    src: url('/fonts/sf-pro-display/SFPRODISPLAYREGULAR.otf');
}
@font-face {
    font-family: 'sfprodisplay-medium';
    src: url('/fonts/sf-pro-display/SFPRODISPLAYMEDIUM.otf');
}
@font-face {
    font-family: 'sfprodisplay-bold';
    src: url('/fonts/sf-pro-display/SFPRODISPLAYBOLD.otf');
}
html, body {
    font-family: 'sfprodisplay-regular';
}

/* Defined Color Theme */
:root {
    --theme-color-primary: #10458b;
    --theme-color-primary-hover: #0e3a72;
    --theme-color-accent: #CA0026;
    --theme-color-accent-hover: #af0020;
    --theme-color-secondary: #FEB415;
    --theme-color-secondary-hover: #da9a10;
    --theme-color-danger: #8b1010;
    --theme-color-danger-hover: #720e0e;
}

/*  */
.glob-title{
    font-family: 'sfprodisplay-bold';
}
.glob-description{
    font-family: 'sfprodisplay-regular';
}
.btn-box{
    display: flex;
}
.btn-box .btn-submit.primary{
    background: var(--theme-color-primary);
    border: none;
    border-radius: 90px;
    color: #ffffff;
    font-family: 'sfprodisplay-bold';
    font-size: 16px;
    padding: 5px 20px;
    transition: all .3s;
    text-decoration: none;
}
.btn-box .btn-submit.primary:hover{
    transition: all .3s;
    background: var(--theme-color-primary-hover);
}
.btn-box .btn-submit.danger{
    background: var(--theme-color-danger);
    border: none;
    border-radius: 90px;
    color: #ffffff;
    font-family: 'sfprodisplay-bold';
    font-size: 16px;
    padding: 5px 20px;
    transition: all .3s;
    text-decoration: none;
}
.btn-box .btn-submit.danger:hover{
    transition: all .3s;
    background: var(--theme-color-danger-hover);
}
.btn-box .btn-submit.secondary{
    background: var(--theme-color-secondary);
    border: none;
    border-radius: 90px;
    color: #000000;
    font-family: 'sfprodisplay-bold';
    font-size: 16px;
    padding: 5px 20px;
    transition: all .3s;
    text-decoration: none;
}
.btn-box .btn-submit.secondary:hover{
    transition: all .3s;
    background: var(--theme-color-secondary-hover);
}
.select2-container--default .select2-selection--single{ border-radius: 90px; }
.select2-container .select2-selection--single{ height: 35px; }
.select2-container .select2-selection--single .select2-selection__rendered{ padding-left: 12px; margin-top: 2px; }
.select2-container--default .select2-selection--single .select2-selection__arrow{
    top: 5px;
    right: 5px;
}
.select2-container--default.select2-container--open.select2-container--below .select2-selection--single, 
.select2-container--default.select2-container--open.select2-container--below .select2-selection--multiple{
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
}

/* Splash Notify */
.splash-notify{
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    align-items: center;
    justify-content: center;
    z-index: 99;
}
.splash-notify .outer{
    max-width: 400px;
    height: 100vh;
    /* background: var(--theme-color-accent); */
    background: linear-gradient(180deg, #CA0026 5.11%, #EF8529 100%);
}
.splash-notify .placement{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 400px;
    padding: 40px;
}
.splash-notify .placement .inner{ text-align: center; }
.splash-notify .placement .inner .icon-status i{
    font-size: 50px;
}
.splash-notify .placement .inner .icon-status.success i{ color: #8eda85; }
.splash-notify .placement .inner .icon-status.error i{ color: #ffa9a9; }
.splash-notify .placement .inner .icon-status.warning i{ color: #ffdb80; }
.splash-notify .placement .inner .status-msg{
    color: #ffffff;
    font-family: 'sfprodisplay-bold';
}

/* Navbar */
nav{
    position: fixed;
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    flex-flow: wrap;
    background: var(--theme-color-secondary);
    z-index: 12;
    align-items: center;
    justify-content: center;
}
nav .outer{
    max-width: 400px;
    width: 400px;
}
nav .outer .row{ align-items: center; }
nav .outer .action{
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

nav.sticky{
    position: fixed;
    box-shadow: none;
    width: 100%;
    height: 80px;
    display: flex;
    align-items: center;
    flex-flow: wrap;
    background:#ffffffeb;
    z-index: 12;
}
nav.with-info{
    height: 120px;
    margin-top: -50px;
}
nav .nav-info{
    width: 100%;
    background: #323b99;
    padding: 7px 30px 7px 20px;
    display: none;
}
nav .nav-info .col-item{
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}
nav .nav-info p{
    margin: 0;
    font-family: 'lucien';
    color: #ffffff;
    font-size: 14px;
    padding-right: 15px;
    line-height: 20px;
}
nav .nav-info p span{
    color: #deda2c;
}
nav .nav-info img{
    width: 70px;
}
nav .nav-info .btn-close-nav-review-google{
    margin-left: 15px;
    color: #ffffff;
}
nav .col-inner{ padding: 0px 15px; }
nav .inner{
    display: flex;
    height: 70px;
    padding: 0px 0px 0px 15px;
}
nav .identity-col{
    padding: 0px 15px 0px 0px;
    display: flex;
    align-items: center;
}
nav .identity-col a{
    font-family: lucien;
    font-size: 40px;
    text-decoration: none;
    color: #ffffff;
}
nav .identity-col a img{
    width: 65px;
    margin-top: -10px;
}
nav .identity-col a img.is-mobile{
    display: none;
    width: 50px;
    margin-top: 0px;
}
nav .menu-col.menu-col-desk{ display: flex; }
nav .menu-col.menu-col-mob{ display: none; }
nav .menu-col{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    position: relative;
}
nav .menu-col.menu-col-desk ul{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding-right: 0;
}
nav .menu-col ul{
    margin: 0;
    padding: 0;
}
nav .menu-col li{
    display: inline-block;
    position: relative;
}
nav .menu-col li a{
    padding: 0px 15px;
    text-decoration: none;
    font-family: 'poppins-medium';
    color: #000000;
    font-size: 14px;
    height: 100%;
    display: flex;
    align-items: center;
    transition: all .2s;
    position: relative;
  }
  nav .menu-col li.active a{ color: var(--theme-color-primary); }
  nav .menu-col li a:hover{ color: var(--theme-color-primary); }
  nav .menu-col li a i{
    margin-left: 5px;
    color: #000000;
    transform: rotate(0deg);
    transition: all .2s;
  }
  nav .menu-col li a i.normal-icon-right{
    margin-right: 5px;
    color: #000000;
    transition: all .2s;
  }
  nav .menu-col li ul.child{
    position: absolute;
    padding: 15px;
    background: #ffffff;
    min-width: 100%;
    width: max-content;
    display: none;
    transition: all .2s;
  }
  nav .menu-col li ul.child li{
    display: block;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  nav .menu-col li ul.child li a{
    padding: 0;
    color: #ffffff;
  }
  nav .menu-col li ul.child li a:hover{ color: #ffffff; }
  
  nav .menu-col li:hover a i{
    transform: rotate(181deg);
    color: #ffffff;
  }
  nav .menu-col li:hover a i.normal-icon-right{
    transform: none;
  }
  nav .menu-col li:hover ul.child{
    display: block;
    transition: all .2s;
  }
  nav .menu-col li.btn-nav{
    padding: 18px 0px;
    margin-left: 10px;
  }
  nav .menu-col li.btn-nav a{
    margin-top: -5px;
    padding: 20px 20px;
    border-radius: 90px;
    border: 2px solid #000000;
    font-family: 'poppins-light';
    font-size: 16px;
    color: #000000;
  }
  nav .menu-col li.btn-nav a:hover{
    background: #000000;
    color: var(--theme-color-primary);
  }
  
  nav .menu-col-mob .btn-create-box{
    display: flex;
    align-items: center;
    margin-right: 60px;
  }
  nav .menu-col-mob .btn-create-box a{
    height: auto;
    padding: 5px 15px;
    border-radius: 90px;
    font-family: 'poppins-light';
    border: 2px solid #000000;
    color: #000000;
    text-decoration: none;
    font-size: 12px;
  }
  nav .menu-col-mob .btn-create-box a:hover{
    background: #000000;
    color: var(--theme-color-primary);
  }
  nav .menu-col-mob .hamburger{
    position: absolute;
    right: 0;
    top: 23px;
    cursor: pointer;
    z-index: 10;
    padding: 10px 15px 5px;
  }
  nav .menu-col-mob .hamburger .bars {
    display: block;
    position: relative;
    width: 28px;
    height: 2px;
    background-color: #000000;
    -webkit-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    -ms-transition: all 0.2s ease;
    transition: all 0.2s ease;
  }
  nav .menu-col-mob .hamburger .bars:before {
    top: 9px;
  }
  nav .menu-col-mob .hamburger .bars:after {
    bottom: 9px;
  }
  nav .menu-col-mob .hamburger .bars:before,
  nav .menu-col-mob .hamburger .bars:after {
    position: absolute;
    content: " ";
    width: 100%;
    height: 2px;
    background-color: #000000;
    -webkit-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    -ms-transition: all 0.2s ease;
    transition: all 0.2s ease;
  }
  nav .menu-col-mob.open .hamburger .bars {
    background-color: transparent;
    -webkit-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    -ms-transition: all 0.2s ease;
    transition: all 0.2s ease;
}
nav .menu-col-mob.open .hamburger .bars::before {
    top: 0;
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
    background-color: #000000;
}
nav .menu-col-mob.open .hamburger .bars::after {
    bottom: 0;
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    background-color: #000000;
}
nav .menu-col-mob .menu {
    width: 90%;
    height: 100%;
    padding: 60px 20px 0;
    background-color: var(--theme-color-primary);
    box-shadow: 0 0 5px rgb(0 0 0 / 70%);
    position: fixed;
    right: -100%;
    top: 0;
    opacity: 0;
    z-index: 9;
    margin-top: 0;
    -webkit-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    -ms-transition: all 0.2s ease;
    transition: all 0.2s ease;
}
nav .menu-col-mob.open .menu {
    right: 0;
    opacity: 1;
    -webkit-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    -ms-transition: all 0.2s ease;
    transition: all 0.2s ease;
}
nav .menu-col-mob .menu .inner{
    width: 100%;
    height: 100%;
    overflow-x: auto;
    display: block;
    padding-bottom: 40px;
}
nav .menu-col-mob .menu li{
    list-style: none;
    display: block;
}
nav .menu-col-mob .menu li.list-w-collapse{ margin-bottom: 30px; }
nav .menu-col-mob .menu li .btn-collapse-a{
    text-decoration: none;
    display: block;
    padding-left: 0;
    padding-right: 35px;
    padding-top: 10px;
    padding-bottom: 10px;
    color: #2196f7;
    border-bottom: 1px solid #323b99;
    font-size: 14px;
    position: relative;
}
nav .menu-col-mob .menu li .btn-collapse-a.collapsed::after{
    transform: rotate(0deg);
}
nav .menu-col-mob .menu li .btn-collapse-a::after{
    content: "\f107";
    font: normal normal normal 28px/1 FontAwesome;
    position: absolute;
    top: 9px;
    right: 10px;
    color: #ffffff;
    transform: rotate(181deg);
    transition: all .3s;
}
nav .menu-col-mob .menu li .card-body{
    background-color: #131a55;
    padding: 15px 0px;
    border-bottom: 1px solid #323b99;
}
nav .menu-col-mob .menu li .card-body ul{
    padding: 0;
    margin-bottom: 0;
}
nav .menu-col-mob .menu li .card-body ul li{ margin-bottom: 15px; }
nav .menu-col-mob .menu li .card-body ul li:last-child{ margin-bottom: 0; }
nav .menu-col-mob .menu li .card-body ul li a{
    padding-left: 0;
    color: #ffffff;
    font-size: 14px;
}
nav .menu-col-mob .menu li .card-body ul li.btn-child-nav a{
    background: #ff4e00;
    height: auto;
    padding: 10px 15px;
    border-radius: 3px;
    font-family: 'montserrat-bolditalic';
    color: #ffffff;
    text-align: center;
    display: block;
}
nav .menu-col-mob .menu li .card-body ul li.btn-child-nav button{
    background: #ff4e00;
    height: auto;
    padding: 10px 15px;
    border-radius: 3px;
    font-family: 'montserrat-bolditalic';
    color: #ffffff;
    border: none;
    width: 100%;
    font-size: 14px;
}
nav .menu-col-mob .menu .auth-box{
    position: absolute;
    top: 15px;
    left: 20px;
    right: 50px;
    display: flex;
}
nav .menu-col-mob .menu li.list-std-acc{ margin-bottom: 0px; }
nav .menu-col-mob .menu li.list-std{ margin-bottom: 10px; }
nav .menu-col-mob .menu li.list-std.active a{ color: #000000; }
nav .menu-col-mob .menu li.list-std-acc a{
    font-family: 'montserrat-medium';
    color: #2196f7;
    font-size: 14px;
    padding: 0;
}
nav .menu-col-mob .menu li.list-std-acc:first-child{ margin-right: 20px; }
nav .menu-col-mob .menu li:hover a i{ transform: rotate(0deg); }
nav .menu-col-mob .menu li.list-std-acc.active a{
    font-family: 'montserrat-bolditalic';
    background: #ff4e00;
    color: #ffffff;
    padding: 5px 15px;
    border-radius: 3px;
}
nav .menu-col-mob .menu li.list-std-acc.active a i{
    padding-right: 5px;
    color: #ffffff;
    margin-left: 0px;
}
nav .menu-col-mob .menu li.list-std a{
    color: #ffffff;
    font-size: 14px;
    padding: 0;
}