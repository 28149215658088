/* Main CSS */

/* Global */
section .glob-inner{
    max-width: 400px;
    width: 400px;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    /* background: var(--theme-color-accent); */
    background: linear-gradient(180deg, #CA0026 5.11%, #EF8529 100%);
    padding: 0px 30px;
    border-top: 20px solid var(--theme-color-secondary);
}
section .glob-inner .box-frame .abs-ornament{
    position: absolute;
    right: 0;
    top: 80px;
    margin-right: -25px;
}
section .glob-inner .box{
    box-shadow: 1px 1px 10px -3px #cccccc;
    padding: 20px;
    border-radius: 10px;
    margin-top: 30px;
    background: #ffffff;
}
section .glob-inner .logo img{ width: 250px; }

/* Home */
section.home{
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
}

/* Attendance */
/* section.attendance{
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    padding-top: 100px;
}
section.attendance .col-inner{
    display: flex;
    justify-content: center;
}
section.attendance .inner{
    max-width: 400px;
    width: 100%;
    padding: 10px;
}
section.attendance .inner .box{
    box-shadow: 1px 1px 10px -3px #cccccc;
    padding: 20px;
    border-radius: 10px;
} */
section.attendance .inner .box #reader{ border: none !important; }
section.attendance .inner .box #reader > div:first-child{ display: none; }
section.attendance .inner .box #reader #reader__dashboard_section_csr button,
section.attendance .inner .box #reader #html5-qrcode-button-file-selection{
    background: var(--theme-color-primary);
    border: none;
    border-radius: 10px;
    color: #ffffff;
    font-family: 'sfprodisplay-bold';
    font-size: 13px;
    padding: 5px 20px;
    transition: all .3s;
    text-decoration: none;
    letter-spacing: 1px;
}